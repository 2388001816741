import React from "react";
import { getClasses } from "@washingtonpost/front-end-utils";
import { useContent } from "fusion-content";
import get from "lodash.get";
import TopperSecondaryNav from "./TopperSecondaryNav";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { TopperPropTypes, topperDefaults } from "../../../../proptypes/topper";

const Topper = ({
  customFields: {
    section = topperDefaults.section,
    alignment = topperDefaults.alignment,
    uppercase = topperDefaults.uppercase
  }
}) => {
  const topperContent = useContent({
    source: "site-service-section",
    query: { section }
  });

  const site = get(topperContent, "q_results[0]", {});
  const secondaryNavId =
    get(site, "site_topper.secondary_nav_alternate_section_to_use") || section;

  // END: secondary nav

  const title = get(site, "site.site_title");
  const url = get(site, "site.site_url");
  const logo = get(site, "site_topper.site_logo_image");
  const tagline = get(site, "site.site_tagline");

  // if either title or logo eval to true, its ok. if neither of them eval to true, is not okay
  const isOk = !!title || !!logo;

  const hpBreakpoints = useBreakpoints();

  if (!isOk || !topperContent) return null;

  const wrapperClassNames = getClasses(
    `hpgrid-max-width mt-auto mr-auto mb-md ml-auto ${alignment}`
  );

  const mobileClassNames = "mr-sm ml-sm mr-0-ns ml-0-ns";

  const titleClassNames = getClasses("font--headline lh-sm", {
    uppercase: !!uppercase
  });

  const taglineClassNames = getClasses(
    "lh-default font-family-georgia font-xxs light gray-dark italic lh-md"
  );

  return (
    <div className={wrapperClassNames}>
      <div
        className={`${mobileClassNames} ${
          !hpBreakpoints.xs ? `pt-md` : `pt-sm`
        }`}
      >
        <h1 className={`${titleClassNames} gray-darkest`}>
          {logo && (
            <img
              src={logo}
              className="mr-sm"
              style={{ maxHeight: "1em" }}
              alt={title}
            />
          )}
          {title}
        </h1>
        {tagline && (
          <h2
            className={taglineClassNames}
            dangerouslySetInnerHTML={{ __html: tagline }}
          />
        )}
      </div>
      <TopperSecondaryNav
        section={secondaryNavId}
        site={site}
        alignment={alignment}
        url={url}
      />
    </div>
  );
};

Topper.propTypes = TopperPropTypes;
Topper.label = "Section - Topper";
export default Topper;
